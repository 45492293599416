body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fff; /* Change this to solid white */
  overflow: hidden;
}

.contact-me {
  text-align: center;
  font-size: 60px;
  margin-top: 5vh;
  margin-bottom: 5vh;
  color:#ddd;
}

.contact-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

form {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}

.input-field {
  margin-bottom: 20px;
}

.input-field input,
.input-field textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.input-field input:focus,
.input-field textarea:focus {
  border-color: #333;
  outline: none;
}

.messageBox {
  height: 150px;
  resize: vertical;
}

.sendButton {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.sendButton:hover {
  background-color: #555;
}

@media (max-width: 768px) {
  .contact-form {
    padding: 20px;
  }

  form {
    padding: 20px;
  }
}

.hidden {
  display: none;
}
